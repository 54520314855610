/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <MKTypography sx={{ textAlign: "center" }} variant="h3" color="black" mt={-6} mb={1}>
          Our Services
        </MKTypography>
        {/* <Grid container spacing={3} alignItems="center"> */}
        {/* <Grid item xs={12} lg={6}> */}
        <Grid mt={4} container justifyContent="flex-start">
          <Grid item xs={12} md={6}>
            <MKBox mb={5}>
              <DefaultInfoCard
                direction="center"
                icon="computer"
                title="Web app development"
                description="We Use modern web technology to build highly responsive websites"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={5}>
              <DefaultInfoCard
                direction="center"
                icon="smartphone"
                title="Mobile App development"
                description="Native android/ios apps, Cross mobile app with react native"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <DefaultInfoCard
                direction="center"
                icon="brush"
                title="UI/UX design"
                description="Create intuative designs for your app/website"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 5, md: 0 }}>
              <DefaultInfoCard
                direction="center"
                icon="3p"
                title="IT Consultation"
                description="We offer Consultation service for you at any time"
              />
            </MKBox>
          </Grid>
        </Grid>
        {/* </Grid> */}
        {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        {/* </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Information;
